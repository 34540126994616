import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-terms-region',
  templateUrl: './terms-region.component.html'
})
export class TermsRegionComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
