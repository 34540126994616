import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-callbacks-region',
  templateUrl: './callbacks-region.component.html'
})
export class CallbacksRegionComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
