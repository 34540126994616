import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-session-region',
  templateUrl: './session-region.component.html'
})
export class SessionRegionComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
